<template>
    <li v-prlx="{
                            reverse: true,
                            speed: 0.2,
                            preserveInitialPosition: true,
                            limit: { max: 0 },
                            fromBottom: false
                        }" >
        <div>
            <b-icon :icon=bloco_servico.icon aria-hidden="true" class="icon-color-1"></b-icon>
            <br>
            <p>{{ bloco_servico.title }}</p>
        </div>
    </li>
</template>

<script>
export default  {
    name: "ItemServico",
    props: {
        bloco_servico: {}
    }
}
</script>

<style scoped lang="scss">

@import "../assets/scss/global";

ul {
    display: flex;
    flex-flow: row wrap;

    li {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 20px;
        padding: 20px;
        border: 1px solid $cor1;
        border-radius: 10px;
        width: 180px;
        height: 140px;
        cursor: default;
        transition: all .3s ease;
        box-shadow: 0 0px 0px 0px rgba($black, .0);

        .icon-color-1 {
            transition: transform .5s ease;
            font-size: 40px;
            margin-bottom: 5px;
        }

        p {
            transition: transform .5s ease;
            font-size: 16px;
        }

        &:hover {
            box-shadow: 0 5px 15px 0px rgba($cor1, .2);

            .icon-color-1 {
                transform: scale(1.3);
            }

            p {
                transition-delay: .2s;
                transform: translateY(5px);
            }
        }
    }
}

    @media (max-width: $mobile){
        ul{
            justify-content: center;
        }
    }

    @media (max-width: $screenSD){
        ul{ 
            li{
                margin: 5px;
                width: 130px;
                height: 140px;
                p{
                    font-size: 13px;
                    line-height: 21px;
                }
            }
        }
    }

</style>
